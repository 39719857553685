a.selectBox {
  &.error {
    border: 1px solid $color-red;
  }
}

.postal_code_container {
  width: 100%;
  position: relative;
  .field {
    width: 42.49%;
    display: block;
  }
  span {
    &.fieldset-note {
      left: 345px;
      position: absolute;
      top: 0;
    }
    &.lookup_submit_note {
      left: 540px;
      position: absolute;
      top: 0;
    }
  }
  .address_lookup_wrapper {
    border: 1px solid $color-red;
    padding: 10px;
    margin: 10px 0;
    font-size: 0.8em;
    h4 {
      font-size: 1.33em;
    }
    .action-close {
      margin: 10px 0;
    }
  }
}

.search-results__header {
  .search-form {
    &__results-count {
      line-height: 35px;
      #{$rdirection}: 181px;
    }
    input[type=submit].search-form__submit {
      margin-#{$ldirection}: 0;
    }
  }
}

#cboxLoadedContent {
  #address_form_container {
    .postal_code_container {
      padding-bottom: 20px;
    }
    span {
      &.fieldset-note {
        left: 200px;
        top: 10px;
      }
      &.lookup_submit_note {
        left: 200px;
        top: 50px;
        width: 250px;
      }
    }
    .address_lookup_wrapper {
      margin-top: 30px;
    }
  }
}

ul.links_list {
  li.link {
    border: 0;
    width: 100%;
  }
}

.store-locator {
  ul.resp-tabs-list {
    .resp-tab-item {
      min-height: 60px;
    }
  }
}

body[data-checkout-release='1'] {
  .pg_wrapper--checkout {
    &.pg_wrapper--viewcart {
      .gift-options-panel {
        .giftwrap {
          .sub-section {
            .btn {
              width: auto;
              min-width: unset;
            }
          }
        }
      }
    }
  }
}
