.preorder_spp_container {
  .pr-snippet-read-and-write,
  .pr-rd-no-reviews {
    .pr-snippet-write-review-link {
      pointer-events: none;
      opacity: 0.6;
      cursor: default;
    }
  }
  .preorder_sold_out {
    display: none;
  }
  .product__flag_preorder {
    .preorder_shade_product {
      display: inline-block;
    }
  }
}

.site-header {
  &__addtobag {
    .product__inventory-status {
      .preorder-noshop {
        display: none !important;
      }
    }
  }
}

.product {
  &__inventory-status {
    .preorder-noshop {
      width: 250px;
      float: $rdirection;
      font-size: 12px;
      text-align: $rdirection;
    }
  }
  &__flag_preorder {
    .product {
      &__shade-preorder {
        display: none;
      }
      &__shade-enable {
        display: inline-block;
      }
    }
  }
}

.payment-container {
  .preorder-text {
    &__content {
      width: 100%;
      margin-top: 20px;
    }
  }
}

.checkout {
  &__sidebar {
    .preorder-panel {
      .cart-items {
        .preorder-text {
          &__content {
            padding-left: 28%;
          }
        }
      }
    }
    .order-summary {
      &__cod-label {
        clear: both;
      }
      &__tax-label {
        line-height: normal;
      }
    }
  }
}

.past-purchases-page {
  .past_purchases_right_container {
    float: $ldirection;
    width: 75%;
    @include breakpoint($tablet-down) {
      width: 70%;
    }
  }
}

.preorder-panel {
  .viewcart-panel {
    &__title {
      margin: 25px 0 10px;
    }
  }
}

.preorder-history-panel {
  margin-bottom: 25px;
}

.preorder-faq {
  &__heading {
    margin: 20px 0 10px;
  }
}
