.forgot-password {
  margin: 0 auto;
  max-width: 1024px;
  .forgot-password__content {
    text-align: left;
    margin: 20px 20px 20px 50px;
    .sent-info {
      width: 65%;
    }
    .forgot-password__header {
      font-weight: bold;
    }
    .sent-info__text,
    .password-reset__text,
    .password-reset__form {
      margin-bottom: 0.8em;
      line-height: 1.45em;
    }
    .password-reset__item {
      margin-bottom: 1em;
      input {
        width: 33.33%;
        display: block;
      }
    }
  }
  .account-utilities__section {
    .account-utilities__header {
      font-size: 21px;
      font-weight: bold;
    }
  }
  &.password-request-page,
  &.password-sent-page,
  &.password-reset-page {
    .account-utilities__section {
      margin: 0;
    }
  }
}

.account__section {
  .account-page__content {
    float: left;
    width: 75%;
    .account-profile {
      &__email {
        word-wrap: break-word;
      }
    }
  }
  .sidebar-right {
    float: right;
  }
  .continue-buttons {
    float: right;
  }
  .info-text {
    margin: 10px 0;
  }
  .account__page-header {
    padding-bottom: 20px;
    border-bottom: 1px solid $color-black;
    margin-bottom: 10px;
  }
  .profile-info__item {
    margin-left: 0;
  }
  #registration-wrapper {
    #address_form_container {
      border: 0;
      padding-left: 0;
      .postal_code_container {
        .postal_container_left {
          .field {
            width: 100%;
          }
        }
        .lookup_results_container {
          float: left;
        }
      }
    }
    .privacy_policy_container {
      margin-left: 20px;
    }
  }
  .optional-info {
    padding-bottom: 20px;
    .gender_container {
      margin-left: 10px;
      label,
      span {
        margin-right: 10px;
      }
    }
  }
  .return-user {
    &__fieldset {
      .forgot_pwd_text {
        display: inline;
      }
    }
  }
}

.account {
  &__new-account,
  &__return-user {
    input[type='text'] {
      margin-bottom: 20px;
    }
  }
}

.promotions-container {
  .newsletter-info__fieldset {
    margin-left: 20px;
    .form_element {
      margin-bottom: 10px;
    }
    a {
      color: $color-pink;
    }
  }
  .sms-promotions {
    a {
      color: $color-pink;
    }
  }
}

.order-details {
  &__item {
    h4 {
      font-weight: bold;
    }
  }
  .order-totals {
    width: 35%;
  }
}

.order-details,
.order-confirmation {
  .order-totals {
    .checkout__panel-title {
      font-family: $brandon-text;
    }
  }
}

.address-container {
  font-style: normal;
}

.order_total {
  float: left;
  margin-right: 23px;
}

input[type='checkbox'] ~ label:before,
input[type='checkbox'] ~ .label:before {
  float: left;
}

@media screen and (max-width: 768px) {
  .account-page {
    .account-utilities {
      width: 20%;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .favorites-page__content {
    margin-right: 10px;
  }
}

.order-details-page__content {
  .order-products {
    .cart-item__desc-info {
      a.sub_line {
        color: #000;
        text-decoration: none;
      }
      .cart-item__monogram-text {
        font-size: 16px;
      }
    }
  }
}

.past-purchases__content,
.favorites-page__content {
  .product-info__sub_line {
    a {
      color: #000;
      text-decoration: none;
    }
  }
}

.favorites-page__content {
  .my-lists__list-actions a {
    vertical-align: top;
  }
}

.order-details-page__content {
  .order-summary__total-label {
    border: none;
    text-align: left;
  }
  .order-summary__total-value {
    border: none;
    font-weight: normal;
  }
  .order-summary__subtotal-label {
    text-align: left;
  }
  .order-totals {
    .subtotal {
      padding: 0.725em 0;
    }
    .total {
      margin-top: 0;
    }
  }
}

.account__new-account {
  .new-account__item {
    input[type='email'] {
      width: 100%;
      display: block;
    }
  }
}

.sign-in {
  .registration {
    &__show-pass {
      margin: 10px 0 0 15px;
      &-text {
        letter-spacing: -0.1em;
      }
    }
  }
}

.address-book-page__content {
  .address-book {
    &__default {
      border-bottom: 1px solid $color-gray;
    }
  }
}

.favorites {
  &__recommended-products {
    .favorites__recommended-product {
      position: relative;
      padding-bottom: 60px !important;
      .product_name {
        min-height: 120px;
      }
      &-actions {
        position: absolute;
        bottom: 0;
      }
    }
  }
}

.account {
  .account-payment-info-page {
    .account-utilities {
      float: left;
      margin-right: 32px;
    }
    .account-payment-info-page {
      &__content {
        width: auto;
        overflow: hidden;
        padding-bottom: 1px;
        .account-payment-info-page_header {
          text-indent: 20px;
          margin-bottom: 0;
          font-family: $brandon-text;
          font-weight: normal;
          font-size: 21px;
          letter-spacing: 0.24em;
          text-transform: uppercase;
          text-indent: 20px;
          margin: 4px 0 17px 0;
          padding-bottom: 16px;
          border-bottom: 2px solid #000000;
        }
        .add-address {
          margin-bottom: 15px;
        }
        .payment_book_container {
          .payment-info {
            &__default,
            &__other {
              margin-bottom: 15px;
            }
            &__header {
              margin-bottom: 30px;
            }
          }
        }
      }
    }
  }
  .address-book-page {
    .payment-info {
      &__default {
        margin-top: 15px;
      }
      .payment-info-book {
        &__header {
          margin: 0 0 10px 0;
          padding: 10px 0 14px 0;
          font-size: 1.5em;
          letter-spacing: 0.15px;
          border-bottom: 1px solid $color-black;
          border-top: 1px solid $color-black;
          font-family: $brandon-text;
        }
      }
      .payment-item {
        &__details {
          padding: 10px 0 0 0;
          border-bottom: none;
        }
      }
      .default-payment {
        font-size: 12px;
      }
    }
  }
}

.replenishment-detail-page {
  .replenishment-page {
    &__header {
      text-indent: 0;
      margin-bottom: 10px;
    }
  }
}

.adpl {
  form#address {
    .container {
      width: 48%;
      display: inline-block;
    }
    .city {
      padding-top: 30px;
      padding-left: 14px;
      padding-right: 6px;
    }
    .postal_code_container {
      .postal_container_left input {
        width: 45.7%;
      }
      .postal_container_right span {
        padding-left: 35px;
      }
    }
    .state {
      label {
        .label-content {
          display: block;
        }
      }
      .selectBox-arrow {
        line-height: 2.5em;
      }
      a {
        height: 3em;
        line-height: 3em;
      }
    }
  }
}

form#signin {
  .return-user__fieldset {
    padding-top: 9px;
    .return-user-email {
      width: 100%;
    }
  }
}

form {
  &.payment_form {
    .payment-form {
      &__item {
        width: 47.5%;
        display: inline-block;
        label {
          pointer-events: none;
        }
        a {
          width: 90%;
          &.year {
            left: 195px;
            bottom: 32px;
          }
        }
      }
    }
  }
}

form#profile_preferences.adpl,
#address_form_container.adpl {
  input[type='email'] + label:before,
  input[type='tel'] + label:before,
  input[type='password'] + label:before,
  input[type='text'] + label:before {
    content: attr(data-required) attr(placeholder);
  }
}

form#profile_preferences.adpl {
  .error_messages.error {
    padding-bottom: 15px;
  }
}

.site-email-sms-signup {
  padding: 0;
  margin: 0 auto;
  max-width: 1280px;
  &__header {
    width: 1280px;
    padding: 10% 0;
    background-image: url('/media/export/cms/loyalty/marketing_pc_banner_bg.jpg');
    background-size: cover;
    font-family: $brandon-text;
    h1 {
      font-family: $brandon-text-bold;
      font-size: 3em;
      letter-spacing: -2px;
    }
    h5 {
      margin-top: 20px;
      font-size: 1.2em;
      line-height: 1.2em;
      margin-bottom: 10px;
    }
  }
  &__content {
    width: 1078px;
    margin: 100px auto;
    h5 {
      margin-top: 20px;
      font-size: 1.2em;
      line-height: 1.2em;
      margin-bottom: 10px;
      text-align: center;
    }
    h1 {
      font-family: $brandon-text-bold;
      font-weight: 700;
      font-size: 3em;
      letter-spacing: -2px;
      text-align: center;
      &.email_header {
        font-size: 30px;
      }
    }
    .site-email-sms-signup__form {
      width: 508px;
      margin: 20px auto;
      .site-email-sms-signup {
        &__field {
          height: 46px;
          margin: 2px;
          &.site-email-sms-signup {
            &__first-name,
            &__last-name {
              width: 210px;
            }
            &__email {
              width: 427px;
            }
            &__birthday {
              text-align: $left;
              padding-top: 10px;
              width: 280px;
              display: block;
              height: auto;
            }
            &__year,
            &__day,
            &__month {
              width: 85px;
            }
          }
        }
        &__postal-code {
          margin: 22px 4px 16px;
          input {
            width: 270px;
            height: 46px;
          }
        }
        &__submit {
          width: 126px;
          height: 46px;
          padding: 20px;
          margin: 2px;
        }
        &__birthday-label {
          display: block;
        }
      }
    }
  }
  &__content-2 {
    margin-top: 90px;
    .site-email-sms-signup {
      &__img {
        background-color: $color-whisper-shade;
        width: 1078px;
        height: 462px;
        margin: 40px 0;
      }
      &__btn {
        text-align: center;
        margin-top: 40px;
        .btn {
          width: 150px;
          height: 50px;
          padding: 15px;
          margin: 2px;
          text-decoration: none;
          background: $color-black;
          color: $color-white;
          display: inline-block;
          text-align: center;
        }
      }
    }
    h1,
    h2,
    h5 {
      width: 690px;
      margin: 0 auto;
    }
    h2 {
      text-align: center;
      padding-top: 20px;
    }
  }
}

.site-email-sms-thank-you {
  background: none;
  padding: 0;
  margin: 0 auto;
  max-width: 1280px;
  &__header {
    width: 1280px;
    padding: 10% 0;
    font-family: $brandon-text;
    h1 {
      font-family: $brandon-text-bold;
      font-weight: 700;
      font-size: 3em;
      letter-spacing: -2px;
    }
  }
  &__content-2 {
    .site-email-sms-signup {
      &__img {
        background-color: $color-whisper-shade;
        width: 1078px;
        height: 462px;
        margin: 40px auto;
      }
      &__btn {
        text-align: center;
        margin-top: 40px;
        .btn {
          width: 126px;
          height: 46px;
          padding: 15px;
          margin: 2px;
          text-decoration: none;
          background: $color-black;
          color: $color-white;
          display: inline-block;
          text-align: center;
        }
      }
    }
    h1 {
      font-family: $brandon-text-bold;
      font-size: 3em;
      letter-spacing: -2px;
      text-align: center;
    }
    h1,
    h2,
    h5 {
      width: 690px;
      margin: 0 auto;
    }
    h2 {
      text-align: center;
      padding-top: 20px;
    }
    h5 {
      margin-top: 20px;
      font-size: 1.2em;
      line-height: 1.2em;
      margin-bottom: 10px;
      text-align: center;
    }
  }
}
