$disable-bbv2-in-checkout: false !default;
$selector-to-disable: "body";

@if $disable-bbv2-in-checkout == true {
  $selector-to-disable: "body:not(.device-mobile):not(.pg-checkout), body.device-mobile > :not(.pg-checkout)";
}
.site-footer__main {
  padding-left: 20px;
  padding-right: 20px;
}

.site-footer__column {
  width: 28%;
  &:nth-child(4) {
    border-right: none;
  }
  &:nth-child(2) {
    .menu {
      &__item {
        @include breakpoint($large-up) {
          width: 100%;
        }
      }
    }
  }
  .language-select {
    margin-bottom: 0;
  }
}

.lp_radio_button {
  input {
    display: none;
  }
}

#footer_sticky_chat {
  min-height: 65px;
}

#lpChat {
  .lp_actions_bar_container {
    .lp_actions_bar {
      .lp_action_item {
        .lp_action_wrapper {
          .lp_title {
            text-transform: none;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .site-header {
    .site-utils {
      .site-utils__inner {
        .site-utils__promo {
          width: 40%;
          height: 60px;
        }
        .site-utils__links {
          width: 55%;
          padding: 10px 0px;
        }
      }
    }
  }
  .js-sticky-header--animate.js-sticky-header {
    .site-utils {
      padding: 0px;
      .site-utils__inner {
        padding: 0px;
        .site-utils__links {
          width: 72%;
          @include breakpoint($medium-portrait-only) {
            width: 100%;
            padding: 0;
          }
        }
      }
      .site-utils__nav .menu__link--lvl-1 {
        font-size: 12px;
      }
    }
  }
}

.section-store-locator {
  .store-locator__links-container {
    .store-locator__dutyfree {
      display: none;
    }
  }
  .store-search-controls {
    a.country {
      display: none !important;
    }
    input.search {
      float: left;
      margin-left: 5px;
    }
    .distance_container {
      display: inline-block;
      float: left;
      margin-left: 5px;
      label {
        display: none;
      }
    }
    .search-submit {
      margin-top: 0px;
    }
  }
}

.site-header {
  .site-utils__links {
    .site-utils__item--live-chat {
      .site-utils__link-text {
        display: block;
      }
      .site-utils__icon {
        display: none;
      }
    }
  }
  .lpStaticButton {
    width: auto;
    margin: 0px;
    td {
      border: none;
      padding: 0px;
    }
    .lpPoweredBy,
    .lpEmtStarRating {
      display: none;
    }
  }
}

.popup-offer {
  .js-popup-offer-signed-up {
    padding: 25px 25px 0;
    overflow-y: hidden;
  }
  &__email-input {
    text-transform: lowercase;
  }
}

.quickshop-wrapper {
  .product__details {
    .product__rating {
      .ask__answer {
        display: none;
      }
    }
  }
}

.tiny-waitlist-overlay {
  .field {
    border-top: 1px solid gray;
    padding-top: 10px;
    .align-r {
      display: inline-block;
    }
  }
  .email_input {
    display: inline-block;
    input.error {
      border: 1px solid red;
    }
  }
}

.product--full {
  .product__rating {
    padding-bottom: 18px;
  }
}

.error {
  color: #ff0000;
}

.site-nav {
  .menu__link--lvl-2 {
    font-weight: bold;
  }
}

.section-customer-service-contact-us {
  .customer-service-section {
    .customer-service-contact-form__category {
      padding: 1em 5px;
      letter-spacing: 0;
      font-family: 'Brandon Text Bold', Meiryo, メイリオ;
      @include breakpoint($tablet-down) {
        padding: 15px 5px;
        letter-spacing: 0;
        font-size: 12px;
      }
    }
    #category-other {
      margin-top: 20px;
      padding: 25px 5px;
    }
    .customer-service-contact-form__input--registered {
      display: block;
    }
  }
  .customer-service-header__title {
    font-family: 'Brandon Text Bold', Meiryo, メイリオ;
  }
}

.site-utils__dropdown {
  .login {
    .error_messages {
      margin-top: 10px;
      margin-left: 20px;
    }
  }
}

.bobbis-beauty-corner {
  margin-top: 30px;
}

.coremetrics-opt-wrapper {
  padding: 10px;
  position: relative;
  td {
    border: none;
    width: 100%;
  }
  .view-opt-out-status {
    margin: 25px 0px 10px 0px;
  }
}

#search-wrapper {
  .product-thumb {
    &__abstract {
      .product__button--add-to-bag.mobile-text {
        display: none !important;
      }
    }
  }
}

.homepage-slide.module {
  margin-bottom: 0;
  top: 0;
  .homepage-slide__abstract--default-y-pos {
    margin: 0px;
  }
}
/* --------- POWER REVIEWS--------- */
#pr-reviewdisplay {
  .p-w-r {
    .pr-review-display {
      .pr-helpful {
        &-yes {
          .pr-helpful-count {
            &:before {
              content: '役に立った';
              font-size: 12px;
            }
          }
        }
        &-no {
          .pr-helpful-count {
            &:before {
              content: '役に立たなかった';
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

.waitlist_thankyou_message {
  margin: 90px 60px;
  font-size: 15px;
}

.forgot_pwd .js-forgot-password {
  display: none;
}

.forgot_pwd .forgot_pwd_text .js-forgot-password {
  display: inline-block;
}

#cboxWrapper {
  #samples-panel {
    .product {
      .product-subhead {
        min-height: 80px;
      }
    }
  }
}

.spp {
  .product__title {
    .product__flag {
      display: inline;
    }
  }
}

.spp-product-layout {
  .sticky-add-to-bag {
    &__ctas-wrapper--shaded {
      padding-top: 17px;
    }
  }
}

.site-header__menu-list {
  @include breakpoint($medium-portrait-only) {
    margin-left: 35px;
  }
}

#{$selector-to-disable} {
  .site-footer {
    &__column-title,
    &-social-links__header {
      font-size: 1.15em !important;
      font-weight: 700;
    }
  }
}

.section-wonder-wheel {
  .game {
    &--spinner {
      .game-overlay {
        &__section {
          &--terms {
            .game-overlay {
              &__text {
                @include breakpoint($medium-up) {
                  font-size: 0.9em;
                }
                @include breakpoint($large-up) {
                  font-size: 1em;
                }
              }
            }
          }
        }
      }
    }
  }
}

.tabbed-products-block {
  .product-brief {
    &__abstract {
      flex: inherit;
      height: 190px;
    }
  }
}

.mpp-container {
  &__content {
    .product-brief {
      &__abstract {
        flex: inherit;
        height: 190px;
      }
    }
  }
}

.spp-cross-sells {
  &__products {
    .product-brief {
      &__abstract {
        flex: inherit;
        height: 190px;
      }
    }
  }
}

.field-collection-slides {
  .snow-globe {
    .game-popup-box {
      .win-info {
        .game-win-title {
          p {
            font-size: 20px;
            font-weight: bold;
          }
        }
        .game-win-description {
          font-size: 15px;
        }
      }
    }
    .game-landing {
      .game-title {
        font-size: 30px;
        font-weight: 1000;
      }
      .game-subtitle {
        font-size: 18px;
        font-weight: 500;
      }
      .game-globe {
        .snowglobe {
          min-height: 658px;
        }
      }
    }
  }
}

